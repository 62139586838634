import store from '@/store';

export default {

    async login(credentials) {
        const url = process.env.VUE_APP_APIURL + '/api/v1/login';
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getters.getToken}`
            },
            body: JSON.stringify(credentials)
        });
        if (response.ok) {
            store.dispatch('setMaintenanceMode', [false]);
            const responseJson = await response.json();  
            if (responseJson.success){
                return responseJson;
            } else {
                throw new Error(response.statusText);
            }
        } else if (response.status === 503) {
            const responseJson = await response.json();
            store.dispatch('setMaintenanceMode', [true, responseJson.message]);
        } else if (response.status === 401) {
            const responseJson = await response.json();
            throw new Error(responseJson.message);
        } else {
            store.dispatch('setMaintenanceMode', [false]);
            throw new Error(response.statusText);
        }
    },

    async settings() {
        const url = process.env.VUE_APP_APIURL + '/api/v1/company/' + process.env.VUE_APP_API_COMPANY_ID + '/get-settings';
        const response = await fetch(url, {
            headers: {
                'Accept': 'application/json',
            }
        });
        if (response.ok) {
            store.dispatch('setMaintenanceMode', [false]);
            let responseJson = await response.json();
            return responseJson;
        } else if (response.status === 503) {
            const responseJson = await response.json();
            store.dispatch('setMaintenanceMode', [true, responseJson.message]);
        } else {
            store.dispatch('setMaintenanceMode', [false]);
            throw new Error(response.status);
        }
    }

}