<template>
    <div v-if="!loading">
        <b-link class="back" @click="$emit('proceed', $store.getters.getSecureAssessments ? $store.getters.getCaptureScreens ? 'popup' : 'permissions' : 'agreement')"><svg-icon icon="back" /></b-link>
        <h1>{{ title }}</h1>
        <div :class="textDir" v-if="intro" v-html="intro"></div>
        <div v-else><p>{{ $t('completeAssessmentTime', [duration[0] + ' ' + $t(duration[1]).toLowerCase()]) }}</p></div>
        <b-button class="p-3 mt-5" block variant="primary" @click="$emit('start')">{{ $t('start') }}</b-button>
    </div>
</template>
<script>
import SvgIcon from '@/components/SvgIcon'
export default {
    name: "AssessmentIntro",
    components: {
        SvgIcon
    },
    props: {
        title: String,
        intro: String,
        duration: Array,
        loading: Boolean,
        textDir: {
            type: String,
            default: ''
        }
    }
}

</script>