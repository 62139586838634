import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import Bugsnag from '@bugsnag/js';
import CourseService from '@/services/CourseService';

Vue.use(Vuex);

const getDefaultState = () => {
    return {
        token: '',
        user: {},
        language: '',
        dir: '',
        permissions: {
            'browser': false,
            'screens': null,
            'capture': false,
            'camera': false,
            'fullscreen': false
        },
        settings: {
            brand_colors: null,
            must_interact: true,
            secure_assessments: true,
            screenshots: false,
            photos: true,
            timeout: 30
        },
        activeCourse: null,
        activeAssessment: null,
        maintenanceMode: false,
        maintenanceModeMessage: '',
        courseRequestQueue: [],
        syncing: false,
        toast: '',
    };
};

export default new Vuex.Store({
    strict: true,
    plugins: [createPersistedState()],
    state: getDefaultState(),
    getters: {
        getToken: state => {
            return state.token;
        },
        getUser: state => {
            return state.user;
        },
        getPermissions: state => {
            return state.permissions;
        },
        getLanguage: state => {
            return state.language;
        },
        getDirection: state => {
            return state.dir;
        },
        getCourse: state => {
            return state.activeCourse;
        },
        getAssessment: state => {
            return state.activeAssessment;
        },
        getIsTrainer: state => {
            if (state.user.type === 'trainer'){
                return true;
            }
            return false;
        },
        getMaintenanceMode: state => {
            return state.maintenanceMode;
        },
        getMaintenanceModeMessage: state => {
            return state.maintenanceModeMessage;
        },
        getCourseRequestQueue: state => {
			return state.courseRequestQueue;
        },
        getSyncing: state => {
            return state.syncing;
        },
        getSecureAssessment: state => {
            return state.settings.secure_assessments;
        },
        getCaptureScreens: state => {
            return state.settings.screenshots;
        },
        getCapturePhotos: state => {
            return state.settings.photos;
        },
        getMustInteract: state => {
            return state.settings.must_interact;
        },
        getMaxTimeout: state => {
            if (state.settings.timeout < 30){
                return 30 * 1000;
            }
            return state.settings.timeout * 1000;
        },

    },
    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token;
        },
        SET_USER: (state, user) => {
            state.user = user;
        },
        RESET: (state) => {
            for (const key in state) {
                if (Object.hasOwnProperty.call(state, key)) {
                    if (key !== 'language' && key !== 'dir'){
                        state[key] = getDefaultState()[key];
                    }                    
                }
            }
        },
        RESET_PERMISSIONS: (state) => {
            state.permissions = {
                'browser': false,
                'screens': null,
                'capture': false,
                'camera': false,
                'fullscreen': false
            }
        },
        SET_PERMISSION: (state, [permission, status]) => {
            state.permissions[permission] = status;
        },
        SET_LANGUAGE: (state, [language, direction]) => {
            state.language = language;
            state.dir = direction;
        },
        SET_COURSE: (state, courseID) => {
            state.activeCourse = courseID;
        },
        SET_ASSESSMENT: (state, assessmentID) => {
            state.activeAssessment = assessmentID;
        },
        SET_MAINTENANCEMODE: (state, [maintenanceMode, maintenanceModeMessage]) => {
            state.maintenanceMode = maintenanceMode;
            if (maintenanceMode){
                state.maintenanceModeMessage = maintenanceModeMessage;
            } else {
                state.maintenanceModeMessage = '';
            }
        },
        SET_QUEUEITEM: (state, request) => {
            state.courseRequestQueue.push(request);
        },
        DELETE_QUEUEITEM: (state, index) => {
            state.courseRequestQueue.splice(index, 1);
        },
        DELETE_QUEUE: (state) => {
            state.courseRequestQueue = [];
        },
        SET_SYNC: (state, syncState) => {
            state.syncing = syncState;
        },
        SET_TOAST: (state, toast) => {
            state.toast = toast;
        },
        SET_SETTINGS: (state, settings) => {
            if (settings.secure_assessments === '1'){
                settings.secure_assessments = true;
            } else {
                settings.secure_assessments = false;
            }
            if (settings.must_interact === '1'){
                settings.must_interact = true;
            } else {
                settings.must_interact = false;
            }
            settings.brand_colours = JSON.parse(settings.brand_colours);
            state.settings = settings;
        },
    },
    actions: {
        login: ({ commit}, { token, user }) => {
            commit('SET_TOKEN', token);
            commit('SET_USER', user);
            Bugsnag.setUser(user.id, user.email, user.name);
        },
        logout: ({ commit }) => {
            commit('RESET');
        },
        resetPermissions: ({commit}) => {
            commit('RESET_PERMISSIONS');
        },
        permissionChanged: ({ commit }, [permission, status]) => {
            commit('SET_PERMISSION', [permission, status]);
        },
        setLanguage: ({ commit }, [language, direction]) => {
            commit('SET_LANGUAGE', [language, direction]);
        },
        setCourse: ({ commit }, courseID) => {
            commit('SET_COURSE', courseID);
        },
        setAssessment: ({ commit }, assessmentID) => {
            commit('SET_ASSESSMENT', assessmentID);
        },
        downloadCourse: ({ commit }, courseID) => {
            commit('DOWNLOAD_COURSE', courseID);
        },
        resyncCourses: ({ commit }, courses) => {
            commit('RESYNC_COURSES', courses);
        },
        deleteCourse: ({ commit }, courseID) => {
            commit('DELETE_COURSE', courseID);
        },
        setMaintenanceMode: ({ commit }, [maintenanceMode, maintenanceModeMessage]) => {
            commit('SET_MAINTENANCEMODE', [maintenanceMode, maintenanceModeMessage]);
        },
        addToRequestQueue: ({ commit }, request) => {
            commit('SET_QUEUEITEM', request);
            commit('SET_TOAST', 'workingOffline');
        },
        removeFromRequestQueue: ({ commit }, index) => {
            commit('DELETE_QUEUEITEM', index);
        },
        emptyRequestQueue: ({ commit }, index) => {
            commit('DELETE_QUEUE', index);
        },
        syncing: ({ getters, commit }) => {
            commit('SET_SYNC', true);
            const queue = getters.getCourseRequestQueue;
            let index = 0;
            for (const queueItem of queue) {
                CourseService[queueItem.type](queueItem.course, queueItem.data ? queueItem.data : null)
                    .then(() => {
                        commit('DELETE_QUEUEITEM', index);
                    });                
                index++;
            }
            commit('SET_SYNC', false);
            if (queue.length){
                commit('SET_TOAST', 'syncFail');
            } else {
                commit('SET_TOAST', 'syncSuccess');
            }
            Vue.nextTick(() => {
                commit('SET_TOAST', '');
            });
        },
        toasting: ({ commit }, toast) => {
            commit('SET_TOAST', toast);
            Vue.nextTick(() => {
                commit('SET_TOAST', '');
            });
        },
        setSettings: ({ commit }, settings) => {
            commit('SET_SETTINGS', settings);
        }
    }
});